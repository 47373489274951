import React from "react";
import { Container, Stack, Typography } from "@mui/material";
import { SentimentDissatisfied } from "@mui/icons-material";

import RoundedButton from "../components/RoundedButton";
import Icons from "../helpers/icons";

const UnAuthorizedScreen = () => {
    return (
        <Container>
            <br />
            <br />
            <br />
            <br />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Typography variant="h1">403</Typography>
                <Icons.MidSadFace color={"primary"} style={{ fontSize: 80 }} />

                <Typography>
                    It seems that you don't have the necessary permissions to
                    access this page.
                </Typography>
                <Typography>
                    If you believe this is an error, please contact the
                    administrator.
                </Typography>
                <br />
                <RoundedButton to="/app">Home</RoundedButton>
            </Stack>
            <br />
            <br />
            <br />
            <br />
        </Container>
    );
};

export default UnAuthorizedScreen;
