import { URLAPI } from "../configuration";
import { get_currency } from "../helpers/currency";
import { hotelIDTypes } from "../types/hotelIDTypes";
import Axios from "axios";

const initial = {
    id: localStorage.getItem("hotelID") || "default",
};

export const hotelIDReducer = (state = initial, action) => {
    switch (action.type) {
        case hotelIDTypes.set:
            const newState = action.payload;
            localStorage.setItem("hotelID", newState.id);
            get_currency(newState.id); // Ask for currency and store it

            return newState;

        case hotelIDTypes.default:
            return action.payload;

        case hotelIDTypes.clean:
            localStorage.removeItem("hotelID");
            return {};

        default:
            return state;
    }
};
