import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";

import {
    Container,
    Typography,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
} from "@mui/material";

import TableHeaderCell from "../components/TableHeaderCell";

import { HOTELS_INFO, URLAPI } from "../configuration";
import Colours from "../helpers/colours";
import Icons from "../helpers/icons";

const HotelIdentifierScreen = () => {
    const auth = useSelector((state) => state.auth);

    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [registeredHotels, setRegisteredHotels] = useState([]);

    useEffect(() => {
        Axios.get(`${URLAPI}/hotels`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                setSites(response.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
        Axios.get(`${URLAPI}/hotels/registered`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        }).then((response) => {
            setRegisteredHotels(response.data);
        });
    }, []);

    return (
        <>
            <Container>
                <h1>Hotel Identifier</h1>
                {loading ? (
                    <Typography>Loading...</Typography>
                ) : sites.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>Hotel ID</TableHeaderCell>
                                <TableHeaderCell>Hotel Name</TableHeaderCell>
                                <TableHeaderCell>Site ID</TableHeaderCell>
                                <TableHeaderCell>
                                    RegisteredHotels
                                </TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sites.map((e) => (
                                <TableRow
                                    key={e.SiteID} // Add a unique key to each table row
                                    sx={{
                                        background:
                                            e.HotelID in HOTELS_INFO
                                                ? Colours.secondarySoft
                                                : null,
                                    }}
                                >
                                    <TableCell>
                                        {e.HotelID ? e.HotelID : ""}
                                    </TableCell>
                                    <TableCell>
                                        {e.HotelName
                                            ? e.HotelName
                                            : "No HotelName"}
                                    </TableCell>
                                    <TableCell>
                                        {e.SiteIDs ? e.SiteIDs : "No SiteID"}
                                    </TableCell>
                                    <TableCell>
                                        {registeredHotels.includes(
                                            Number(e.HotelID)
                                        ) && <Icons.StatusComplete />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Typography>No data available.</Typography>
                )}
            </Container>
        </>
    );
};

export default HotelIdentifierScreen;
