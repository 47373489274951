import React from "react";

import EChartsReact from "echarts-for-react";
import { Grid } from "@mui/material";

import WrapperPDFEChart from "../shared/WrapperPDFEChart";

import Colours from "../../helpers/colours";
import { get_currency } from "../../helpers/currency";

const RevenueComparisonMonth = ({
    thisTitle,
    lastTitle,
    thisRevenue,
    thisVouchers,
    thisCancellations,
    lastRevenue,
    lastVouchers,
    lastCancellations,
    hotel_id,
    symbol = null,
    revenueName = "Revenue",
    cancellationsName = "Cancellations",
    noSymbol = false,
    noCentralLabel = false,
}) => {
    let currency = get_currency(hotel_id);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <WrapperPDFEChart k={lastTitle}>
                    <EChartsReact
                        style={{
                            height: "400px",
                            width: "100%",
                        }}
                        option={{
                            title: {
                                left: "center",
                                text: lastTitle,
                            },
                            legend: {
                                orient: "vertical",
                                bottom: "0%",
                                left: "center",
                                height: "30%",
                                formatter: (name) =>
                                    `${name} ${
                                        !noSymbol ? symbol || currency : ""
                                    }${
                                        name === revenueName
                                            ? lastRevenue
                                            : name === "Vouchers"
                                            ? lastVouchers
                                            : lastCancellations
                                    }`,
                                textStyle: {
                                    rich: {
                                        title: {
                                            color: "#666",
                                            lineHeight: 30,
                                        },
                                        value: {
                                            color: "#333",
                                            fontSize: 14,
                                        },
                                    },
                                },
                            },
                            series: [
                                {
                                    type: "pie",
                                    center: ["50%", "45%"],
                                    radius: ["40%", "70%"],
                                    avoidLabelOverlap: false,
                                    itemStyle: {
                                        borderRadius: 10,
                                        borderColor: "#fff",
                                        borderWidth: 2,
                                    },
                                    label: noCentralLabel
                                        ? {
                                              show: true,
                                              formatter: "{b}:\n{c}\n({d}%)",
                                          }
                                        : {
                                              show: true,
                                              position: "center",
                                              fontSize: 36,
                                              fontWeight: "bold",
                                              formatter: () =>
                                                  `${
                                                      !noSymbol
                                                          ? symbol || currency
                                                          : ""
                                                  }${
                                                      lastRevenue +
                                                      lastCancellations +
                                                      (lastVouchers ?? 0)
                                                  }`,
                                          },
                                    labelLine: {
                                        show: false,
                                    },
                                    data: [
                                        {
                                            value: lastRevenue,
                                            name: revenueName,
                                            itemStyle: {
                                                color: Colours.graphDisabledDarker,
                                            },
                                        },
                                        {
                                            value: lastCancellations,
                                            name: cancellationsName,
                                            itemStyle: {
                                                color: Colours.graphDisabled,
                                            },
                                        },
                                        ...(lastVouchers
                                            ? [
                                                  {
                                                      value: lastVouchers,
                                                      name: "Vouchers",
                                                      itemStyle: {
                                                          color: Colours.graphDisabledLighter,
                                                      },
                                                  },
                                              ]
                                            : []),
                                    ],
                                },
                            ],
                        }}
                    />
                </WrapperPDFEChart>
            </Grid>
            <Grid item xs={12} md={6}>
                <WrapperPDFEChart k={thisTitle}>
                    <EChartsReact
                        style={{
                            height: "400px",
                            width: "100%",
                        }}
                        option={{
                            title: {
                                left: "center",
                                text: thisTitle,
                            },
                            legend: {
                                orient: "vertical",
                                bottom: "0%",
                                left: "center",
                                height: "30%",
                                formatter: (name) =>
                                    `${name} ${
                                        !noSymbol ? symbol || currency : ""
                                    }${
                                        name === revenueName
                                            ? thisRevenue
                                            : name === "Vouchers"
                                            ? thisVouchers
                                            : thisCancellations
                                    }`,
                                textStyle: {
                                    rich: {
                                        title: {
                                            color: "#666",
                                            lineHeight: 30,
                                        },
                                        value: {
                                            color: "#333",
                                            fontSize: 14,
                                        },
                                    },
                                },
                            },
                            series: [
                                {
                                    type: "pie",
                                    center: ["50%", "45%"],
                                    radius: ["40%", "70%"],
                                    avoidLabelOverlap: false,
                                    itemStyle: {
                                        borderRadius: 10,
                                        borderColor: "#fff",
                                        borderWidth: 2,
                                    },
                                    label: noCentralLabel
                                        ? {
                                              show: true,
                                              formatter: "{b}:\n{c}\n({d}%)",
                                          }
                                        : {
                                              show: true,
                                              position: "center",
                                              fontSize: 36,
                                              fontWeight: "bold",
                                              formatter: () =>
                                                  `${
                                                      !noSymbol
                                                          ? symbol || currency
                                                          : ""
                                                  }${
                                                      thisRevenue +
                                                      thisCancellations +
                                                      (thisVouchers ?? 0)
                                                  }`,
                                          },
                                    labelLine: {
                                        show: false,
                                    },
                                    data: [
                                        {
                                            value: thisRevenue,
                                            name: revenueName,
                                            itemStyle: {
                                                color: Colours.graphSold,
                                            },
                                        },
                                        {
                                            value: thisCancellations,
                                            name: cancellationsName,
                                            itemStyle: {
                                                color: Colours.graphMainLine2,
                                            },
                                        },
                                        ...(thisVouchers
                                            ? [
                                                  {
                                                      value: thisVouchers,
                                                      name: "Vouchers",
                                                      itemStyle: {
                                                          color: Colours.graphExtraLine1,
                                                      },
                                                  },
                                              ]
                                            : []),
                                    ],
                                },
                            ],
                        }}
                    />
                </WrapperPDFEChart>
            </Grid>
        </Grid>
    );
};

export default RevenueComparisonMonth;
