import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import * as echarts from "echarts/lib/echarts.js";

import {
    FormControl,
    MenuItem,
    Select,
    Stack,
    Typography,
    Grid,
    Table,
    TableBody,
    Box,
    Button,
    Container,
    // Tooltip,
} from "@mui/material";

import EChartsReact from "echarts-for-react";

import NoDataRow from "./NoDataRow";
import ToggleButtonTitled from "./ToggleButtonTitled";

import {
    dateToUTC,
    months_abbreviations,
    newDateUTC,
    startOfWeekDate,
} from "../helpers/dates";
import {
    DISPLAY_ELEMENT_HELPER,
    GB_NotDate,
    GROUP_OFFERS,
} from "../helpers/filters";
import { tooltip, tooltipST } from "../helpers/eCharts";
import { fns } from "../helpers/common";
import HoverPaper from "./shared/HoverPaper";
import Colours, { pastelColours } from "../helpers/colours";
import Icons from "../helpers/icons";

import { get_currency } from "../helpers/currency";

const monthlyIntervals = (fromDay, toDay) => {
    const fromDate = dateToUTC(fromDay);
    const toDate = dateToUTC(toDay);
    const intervals = [];

    while (fromDate <= toDate) {
        const yearMonth = dateToUTC(fromDate).toISOString().substr(0, 7);
        intervals.push(yearMonth);
        fromDate.setUTCDate(1); // Set to the first day of the next month
        fromDate.setUTCMonth(fromDate.getUTCMonth() + 1); // Increment the month
    }

    return intervals;
};

const weeklyIntervals = (fromDay, toDay) => {
    const fromDate = startOfWeekDate(fromDay);
    const toDate = dateToUTC(toDay);
    const intervals = [];

    while (fromDate <= toDate) {
        let weekStart = fromDate;
        const yearMonthWeekStart = dateToUTC(weekStart)
            .toISOString()
            .substr(0, 10);

        intervals.push(
            dateToUTC(yearMonthWeekStart).toISOString().substr(0, 10)
        );

        fromDate.setUTCDate(fromDate.getUTCDate() + 7); // Move to next week
        weekStart = startOfWeekDate(fromDate);
    }

    return intervals;
};

const dailyIntervals = (fromDay, toDay) => {
    const fromDate = dateToUTC(fromDay);
    const toDate = dateToUTC(toDay);
    const intervals = [];

    while (fromDate <= toDate) {
        const yearMonthDay = dateToUTC(fromDate).toISOString().substr(0, 10);
        intervals.push(yearMonthDay);
        fromDate.setUTCDate(fromDate.getUTCDate() + 1);
    }

    return intervals;
};

const TrendByChart = ({
    js,
    data,
    entriesLimit = 7,
    trendBy = GROUP_OFFERS, //TODO: Think about this, with the new "processBy" state, this could be unnecessary
    metrics = {
        bookingsTotal: {
            ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
            short: "Bookings",
        },
        revenueTotal: { ...DISPLAY_ELEMENT_HELPER.revenueTotal },
        roomNightsTotal: { ...DISPLAY_ELEMENT_HELPER.roomNightsTotal },
    },
    title = "",
}) => {
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [render, setRender] = useState(false);

    const [processBy, setProcessBy] = useState(trendBy);
    const [breakdownMode, setBreakdownMode] = useState("");
    const [valueType, setValueType] = useState(Object.keys(metrics)[0] ?? "");

    const [update, setUpdate] = useState(0);

    const [dataByBreakdown, setDataByBreakdown] = useState({});
    const [breakdownSeries, setBreakdownSeries] = useState({});

    const [series, setSeries] = useState({});
    const [legend, setLegend] = useState({});
    const [options, setOptions] = useState({});
    const chartRef = useRef(null);

    useEffect(() => {
        setRender(false);
        if (
            js === undefined ||
            !js.hasOwnProperty("fromDay") ||
            !js.hasOwnProperty("toDay") ||
            !js.hasOwnProperty("groupBy") ||
            Object.entries(data).length === 0
        )
            return;

        let monthly = monthlyIntervals(js.fromDay, js.toDay);
        let weekly = weeklyIntervals(js.fromDay, js.toDay);
        let daily = dailyIntervals(js.fromDay, js.toDay);

        setBreakdownSeries({ monthly, weekly, daily });

        let aux_processBy = GB_NotDate(js)[0];
        setProcessBy(aux_processBy);

        if (
            GB_NotDate(js).includes(aux_processBy) &&
            js.groupBy.includes("Month") &&
            js.groupBy.includes("Year") &&
            js.groupBy.includes("Day") &&
            js.groupBy.length === 4
        )
            setRender(true);

        if (Object.keys(data).length > 0) {
            let processedMonthly = {};
            let processedWeekly = {};
            let processedDaily = {};

            let groupedMonthly = {}; // { Offer1: { bookingsTotal: {date1: value, ... , dateN: value}, .... }, OfferX: { ... } }
            let groupedWeekly = {};
            let groupedDaily = {};
            let sums = {}; // { bookingsTotal: { Offer1: bookingsValue1,  ... , OfferX: bookingsValueX }, otherValueType: { ... } }

            Object.values(data).forEach(({ keys, result }) => {
                // Iterates through every group
                const valueKeys = Object.keys(metrics).map((type) => [type, 0]); // [ ["bookingsTotal", 0], ... ]

                const {
                    Year = new Date().getUTCFullYear(),
                    Month = 1,
                    Day = 1,
                    [aux_processBy]: TrendBy = "",
                } = Object.fromEntries(keys);

                // Month processing
                if (!groupedMonthly[TrendBy]) {
                    groupedMonthly[TrendBy] = {};
                    sums[TrendBy] = Object.fromEntries(valueKeys);
                }
                let monthDateStamp = newDateUTC(Year, Month - 1, 1)
                    .toISOString()
                    .substring(0, 7);
                if (!groupedMonthly[TrendBy][monthDateStamp]) {
                    groupedMonthly[TrendBy][monthDateStamp] =
                        Object.fromEntries(valueKeys); // { bookingsTotal = 0, ... }
                }
                Object.keys(groupedMonthly[TrendBy][monthDateStamp]).forEach(
                    (key) => {
                        groupedMonthly[TrendBy][monthDateStamp][key] +=
                            result[key] ?? 0; // { bookingsTotal += result.bookingsTotal,

                        sums[TrendBy][key] += result[key] ?? 0;
                    }
                );

                // Week processing
                if (!groupedWeekly[TrendBy]) {
                    groupedWeekly[TrendBy] = {};
                }
                let weekDateStamp = startOfWeekDate(
                    newDateUTC(Year, Month - 1, Day)
                )
                    .toISOString()
                    .substring(0, 10);
                if (!groupedWeekly[TrendBy][weekDateStamp]) {
                    groupedWeekly[TrendBy][weekDateStamp] =
                        Object.fromEntries(valueKeys); // { bookingsTotal = 0, ... }
                }
                Object.keys(groupedWeekly[TrendBy][weekDateStamp]).forEach(
                    (key) => {
                        groupedWeekly[TrendBy][weekDateStamp][key] +=
                            result[key] ?? 0; // { bookingsTotal += result.bookingsTotal,
                    }
                );

                // Day processing
                if (!groupedDaily[TrendBy]) {
                    groupedDaily[TrendBy] = {};
                }
                let dailyDateStamp = newDateUTC(Year, Month - 1, Day)
                    .toISOString()
                    .substring(0, 10);
                if (!groupedDaily[TrendBy][dailyDateStamp]) {
                    groupedDaily[TrendBy][dailyDateStamp] =
                        Object.fromEntries(valueKeys); // { bookingsTotal = 0, ... }
                }
                Object.keys(groupedDaily[TrendBy][dailyDateStamp]).forEach(
                    (key) => {
                        groupedDaily[TrendBy][dailyDateStamp][key] +=
                            result[key] ?? 0; // { bookingsTotal += result.bookingsTotal,
                    }
                );
            });

            const sortedTrend = Object.fromEntries(
                Object.keys(metrics).map((type) => [type, []]) // { bookingsTotal: [[Offer, value], ...], ... }
            );

            Object.keys(sums).forEach((trend) => {
                Object.keys(metrics).forEach((type) => {
                    sortedTrend[type].push([trend, sums[trend][type]]);
                });
            });

            Object.keys(sortedTrend).forEach((type) => {
                // T = each Type
                sortedTrend[type].sort((a, b) => b[1] - a[1]);

                let topN =
                    sortedTrend[type].length > entriesLimit
                        ? entriesLimit
                        : sortedTrend[type].length;

                processedMonthly[type] = {};
                processedWeekly[type] = {};
                processedDaily[type] = {};

                sortedTrend[type]
                    .slice(0, topN)
                    .forEach(([trend, valueTotal]) => {
                        // O: each Offer (top offers) = T^O
                        // O = each Offer --- T^O
                        processedMonthly[type][trend] = Object.entries(
                            groupedMonthly[trend]
                        ).map(([date, obj]) => [date, obj[type]]);
                        processedMonthly[type][trend].sort((a, b) =>
                            a[0].localeCompare(b[0])
                        );

                        processedWeekly[type][trend] = Object.entries(
                            groupedWeekly[trend]
                        ).map(([date, obj]) => [date, obj[type]]);
                        processedWeekly[type][trend].sort((a, b) =>
                            a[0].localeCompare(b[0])
                        );

                        processedDaily[type][trend] = Object.entries(
                            groupedDaily[trend]
                        ).map(([date, obj]) => [date, obj[type]]);
                        processedDaily[type][trend].sort((a, b) =>
                            a[0].localeCompare(b[0])
                        );
                    });

                let OthersMonthly = {};
                let OthersWeekly = {};
                let OthersDaily = {};

                sortedTrend[type]
                    .slice(topN, sortedTrend[type].length)
                    .forEach(([trend, value]) => {
                        // O: each Offer(last offers) = T^O

                        Object.entries(groupedMonthly[trend]).forEach(
                            ([date, obj]) => {
                                // M: each month = T^O^D
                                if (!OthersMonthly[date]) {
                                    OthersMonthly[date] = obj[type];
                                } else {
                                    OthersMonthly[date] += obj[type];
                                }
                            }
                        );

                        Object.entries(groupedWeekly[trend]).forEach(
                            ([date, obj]) => {
                                // W: each week = T^O^W
                                if (!OthersWeekly[date]) {
                                    OthersWeekly[date] = obj[type];
                                } else {
                                    OthersWeekly[date] += obj[type];
                                }
                            }
                        );

                        Object.entries(groupedDaily[trend]).forEach(
                            ([date, obj]) => {
                                // D: each day = T^O^D
                                if (!OthersDaily[date]) {
                                    OthersDaily[date] = obj[type];
                                } else {
                                    OthersDaily[date] += obj[type];
                                }
                            }
                        );
                    });

                if (Object.keys(OthersMonthly).length > 0) {
                    processedMonthly[type]["Others"] = Object.entries(
                        OthersMonthly
                    ).sort((a, b) => a[0].localeCompare(b[0]));
                }

                if (Object.keys(OthersWeekly).length > 0) {
                    processedWeekly[type]["Others"] = Object.entries(
                        OthersWeekly
                    ).sort((a, b) => a[0].localeCompare(b[0]));
                }
                if (Object.keys(OthersDaily).length > 0) {
                    processedDaily[type]["Others"] = Object.entries(
                        OthersDaily
                    ).sort((a, b) => a[0].localeCompare(b[0]));
                }
            });
            setValueType(Object.keys(metrics)[0] ?? "");

            setDataByBreakdown({
                monthly: processedMonthly,
                weekly: processedWeekly,
                daily: processedDaily,
            });
        }
    }, [js, data]);

    useEffect(() => {
        if (Object.values(dataByBreakdown).length > 0) {
            let maxNumberOfMonths = breakdownSeries["monthly"].length;

            const auxSeriesMonthly = Object.entries(
                dataByBreakdown["monthly"][valueType]
            )
                .reverse()
                .map(([name, data], index) => {
                    let dataObj = Object.fromEntries(data);

                    let unreversedIndex =
                        Object.keys(dataByBreakdown["monthly"][valueType])
                            .length -
                        (index + 1);
                    return {
                        type: "bar",
                        name: name,
                        stack: "total",
                        data: breakdownSeries["monthly"].map(
                            (date) => dataObj[date] ?? 0
                        ),
                        smooth: true,
                        symbol: "none",
                        emphasis: {
                            focus: "series",
                        },
                        itemStyle: {
                            normal: {
                                color: pastelColours[unreversedIndex], // Set the color here
                            },
                        },
                    };
                });

            let maxNumberOfWeeks = breakdownSeries["weekly"].length;
            const auxSeriesWeekly = Object.entries(
                dataByBreakdown["weekly"][valueType]
            )
                .reverse()
                .map(([name, data], index) => {
                    let dataObj = Object.fromEntries(data);
                    let unreversedIndex =
                        Object.keys(dataByBreakdown["monthly"][valueType])
                            .length -
                        (index + 1);
                    return {
                        type: "line",
                        name: name,
                        data: breakdownSeries["weekly"].map(
                            (date) => dataObj[date] ?? 0
                        ),
                        stack: "total",
                        smooth: true,
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        itemStyle: {
                            normal: {
                                color: pastelColours[unreversedIndex], // Set the color here
                            },
                        },
                    };
                });

            let maxNumberOfDays = breakdownSeries["daily"].length;
            const auxSeriesDaily = Object.entries(
                dataByBreakdown["daily"][valueType]
            )
                .reverse()
                .map(([name, data], index) => {
                    let dataObj = Object.fromEntries(data);
                    let unreversedIndex =
                        Object.keys(dataByBreakdown["monthly"][valueType])
                            .length -
                        (index + 1);
                    return {
                        type: "line",
                        name: name,
                        data: breakdownSeries["daily"].map(
                            (date) => dataObj[date] ?? 0
                        ),
                        stack: "total",
                        smooth: true,
                        symbol: "none",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        itemStyle: {
                            normal: {
                                color: pastelColours[unreversedIndex], // Set the color here
                            },
                        },
                    };
                });

            let auxSeries = {};

            if (maxNumberOfMonths >= 2) auxSeries["monthly"] = auxSeriesMonthly;

            if (maxNumberOfWeeks >= 2 && maxNumberOfMonths < 12)
                auxSeries["weekly"] = auxSeriesWeekly;

            if (maxNumberOfDays >= 1 && maxNumberOfMonths <= 2)
                auxSeries["daily"] = auxSeriesDaily;

            const auxBreakdownMode = Object.keys(auxSeries).includes(
                breakdownMode
            )
                ? breakdownMode
                : Object.keys(auxSeries)[0] ?? "";
            setBreakdownMode(auxBreakdownMode);
            setSeries(auxSeries);

            let auxLegend = Object.values(auxSeries[auxBreakdownMode] ?? {})
                .reverse()
                .reduce((prev, curr) => {
                    prev[curr.name] = {
                        show: true,
                        color: curr.itemStyle.normal.color,
                    };
                    return prev;
                }, {});

            setLegend(auxLegend);
        }
    }, [dataByBreakdown, breakdownSeries, valueType]);

    useEffect(() => {
        setUpdate(update + 1);
        let auxOptions = {};

        let formatterLabel =
            breakdownMode === "monthly"
                ? (dateStr) => {
                      let { year, month } = dateToUTC(new Date(dateStr), true);
                      return `${months_abbreviations[month]} ${year}`;
                  }
                : breakdownMode === "weekly" || breakdownMode === "daily"
                ? (dateStr) => {
                      let { year, month, day } = dateToUTC(
                          new Date(dateStr),
                          true
                      );
                      return `${months_abbreviations[month]} ${day}, ${year}`;
                  }
                : (dateStr) => dateStr;

        if (Object.keys(series).length > 0 && breakdownMode !== "") {
            // console.log(series[breakdownMode]);

            // Calculate the total value for each category (bar)
            const totals = [];
            const numCategories = series[breakdownMode][0].data.length;
            for (let i = 0; i < numCategories; i++) {
                let total = 0;
                series[breakdownMode].forEach((s) => {
                    total += s.data[i];
                });
                totals.push(total);
            }

            // Add labels to the last series to display the totals
            const lastSeriesIndex = series[breakdownMode].length - 1;
            series[breakdownMode][lastSeriesIndex].label = {
                show: true,
                position: "top",
                formatter: (params) => {
                    return fns(hotelID, totals[params.dataIndex], {});
                },
                fontSize: 13,
                color: "black",
            };

            auxOptions = {
                legend: {
                    show: false,
                },
                tooltip: {
                    ...tooltip,
                    formatter: (params) => {
                        return tooltipST(params, {
                            formatter: (value) =>
                                fns(
                                    hotelID,
                                    value,
                                    // valueTypes[valueType].format ?? {}
                                    {}
                                ),
                            sort: "asc",
                        });
                    },
                },
                xAxis: {
                    type: "category",
                    boundaryGap: breakdownMode === "monthly" ? true : false,
                    data:
                        breakdownMode === "weekly"
                            ? breakdownSeries[breakdownMode].map(
                                  (dateStr, index) =>
                                      index === 0
                                          ? formatterLabel(js.fromDay) // Start of a week can be from other month so correct it
                                          : formatterLabel(dateStr)
                              )
                            : breakdownSeries[breakdownMode].map((dateStr) =>
                                  formatterLabel(dateStr)
                              ),
                },
                yAxis: {
                    axisLabel: {
                        formatter: function (value) {
                            if (value >= 1000) {
                                return `${value / 1000}k`;
                            }
                            return value;
                        },
                    },
                    name: `${metrics[valueType].short}`,
                    nameLocation: "middle",
                    nameGap: 50,
                    nameTextStyle: {
                        fontSize: 14,
                    },
                },
                series: series[breakdownMode],
                graphic: [
                    {
                        type: "text",
                        left: 120,
                        top: 20,
                        style: {
                            text:
                                valueType === "revenueTotal"
                                    ? `${get_currency(hotelID)}`
                                    : "",
                            fontSize: 18,
                            fill: "grey",
                        },
                    },
                ],
            };
            setOptions(auxOptions);
        }
    }, [series, breakdownMode]);

    const handleOnClickLegend = (cat) => {
        const echartInstance = chartRef.current.getEchartsInstance();
        let auxLegend = {
            ...legend,
        };
        auxLegend[cat].show = !legend[cat].show;

        setLegend(auxLegend);

        echartInstance.dispatchAction({
            type: "legendToggleSelect",
            name: cat,
        });
    };
    const handleOnMouseOverLegend = (cat) => {
        const echartInstance = chartRef.current.getEchartsInstance();
        echartInstance.dispatchAction({
            type: "highlight",
            seriesName: cat,
        });
    };
    const handleOnMouseOutLegend = (cat) => {
        const echartInstance = chartRef.current.getEchartsInstance();
        echartInstance.dispatchAction({
            type: "downplay",
            seriesName: cat,
        });
    };

    return (
        <>
            <Typography fontWeight={"bold"} sx={{ pt: 2 }}>
                {title}
            </Typography>

            <Container
                maxWidth={false}
                component={HoverPaper}
                sx={{ p: 2, backgroundColor: Colours.notificationCard }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item>
                        <Typography>Breakdown </Typography>

                        <FormControl>
                            <Select
                                labelId="breakdownTrendByLabel"
                                id="breakdownTrendBySelect"
                                value={breakdownMode ?? "Select..."}
                                sx={{
                                    height: "50px",
                                }}
                                onChange={(event) => {
                                    setBreakdownMode(event.target.value);
                                }}
                                size="small"
                            >
                                <MenuItem
                                    key={"monthlyBreakdown"}
                                    value={"monthly"}
                                    disabled={!series.hasOwnProperty("monthly")}
                                >
                                    Monthly
                                </MenuItem>
                                <MenuItem
                                    key={"weeklyBreakdown"}
                                    value={"weekly"}
                                    disabled={!series.hasOwnProperty("weekly")}
                                >
                                    Weekly
                                </MenuItem>
                                <MenuItem
                                    key={"dailyBreakdown"}
                                    value={"daily"}
                                    disabled={!series.hasOwnProperty("daily")}
                                >
                                    Daily
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <ToggleButtonTitled
                        valueSet={valueType}
                        values={Object.fromEntries(
                            Object.keys(metrics).map((valueKey) => [
                                valueKey,
                                metrics[valueKey].short,
                            ])
                        )}
                        onChange={(e, v) => {
                            setValueType(v);
                        }}
                    />
                </Stack>
                {render ? (
                    <>
                        <EChartsReact
                            key={update}
                            ref={chartRef}
                            option={options}
                            style={{
                                width: "100%",
                                height: "500px",
                            }}
                        />
                        <Stack
                            direction="row"
                            justifyContent="center"
                            flexWrap={"wrap"}
                        >
                            {Object.keys(legend).length > 0 &&
                                chartRef &&
                                Object.keys(legend).map((cat, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            id={`series-${cat}`}
                                            variant="outlined"
                                            sx={{
                                                paddingY: 1,
                                                paddingX: 0.5,
                                                m: 1,
                                                textTransform: "none",
                                                color: "black",
                                                border: "1px solid lightgrey",

                                                opacity: () =>
                                                    legend[cat].show ? 1 : 0.5,
                                            }}
                                            onClick={() =>
                                                handleOnClickLegend(cat)
                                            }
                                            onMouseOver={() =>
                                                handleOnMouseOverLegend(cat)
                                            }
                                            onMouseOut={() =>
                                                handleOnMouseOutLegend(cat)
                                            }
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                display="flex"
                                            >
                                                <Icons.Square
                                                    sx={{
                                                        color: legend[cat]
                                                            .color,
                                                        marginRight: 0.5,
                                                        pointerEvents: "none",
                                                        fontSize: "20px",
                                                    }}
                                                />

                                                <Typography fontSize="14px">
                                                    {cat}
                                                </Typography>
                                            </Stack>
                                        </Button>
                                    );
                                })}
                        </Stack>{" "}
                    </>
                ) : (
                    <Table>
                        <TableBody>
                            <NoDataRow text="No data for these filters" />
                        </TableBody>
                    </Table>
                )}
            </Container>
        </>
    );
};

export default TrendByChart;
