import React, { useEffect, useState } from "react";

import TabSelector from "./TabSelector";

import YearPerformance from "./reports/YearPerformance";
import TopsComparisonMonth from "./reports/TopsComparisonMonth";

import { get_currency } from "../helpers/currency";

const MonthlyTopCountries = ({ data, month, year, hotel_id }) => {
    const TITLE = "Top Countries";
    const [cardSelected, setCardSelected] = useState("total");
    const [topCountriesData, setTopCountriesData] = useState([]);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const auxTopCountriesData = [
                {
                    id: "total",
                    title: "Total this month",
                    format: {
                        left: get_currency(hotel_id),
                    },
                    value: 86102,
                    old: 75255,
                    expand: true,
                    compare: true,
                },
                {
                    id: "topCountry",
                    title: "Top country this month",
                    value: "Ireland",
                    noArrow: true,
                    expand: true,
                },
                {
                    id: "revenuePerRoomNight",
                    title: "Revenue per Room Night",
                    format: {
                        left: get_currency(hotel_id),
                    },
                    value: 254,
                    old: 230,
                    expand: true,
                    compare: true,
                },
            ];
            setTopCountriesData(auxTopCountriesData);
        }
    }, [data]);

    return (
        <div>
            <TabSelector
                // hotelID={hotelID}
                data={topCountriesData}
                cardSelected={cardSelected}
                setCardSelected={setCardSelected}
            />
            <br />
            {cardSelected === "total" && (
                <TopsComparisonMonth
                    thisTitle={`${month} ${year}`}
                    lastTitle={`${month} ${year - 1}`}
                    thisData={data.tabs.CountriesBreakdown.historical.this}
                    lastData={data.tabs.CountriesBreakdown.historical.last}
                    topBy={"value"}
                    format={{ left: "currency" }}
                    hotel_id={hotel_id}
                    compareLast
                />
            )}
            {cardSelected === "topCountry" && (
                <TopsComparisonMonth
                    thisTitle={`${month} ${year}`}
                    lastTitle={`${month} ${year - 1}`}
                    thisData={data.tabs.TopCountry.historical.this}
                    lastData={data.tabs.TopCountry.historical.last}
                    topBy={"value"}
                    format={{ left: "currency" }}
                    hotel_id={hotel_id}
                    compareLast
                />
            )}

            {cardSelected === "revenuePerRoomNight" && (
                <YearPerformance
                    TITLE={`${TITLE} - ${
                        topCountriesData.length > 0
                            ? topCountriesData.find(
                                  (head) => head.id === cardSelected
                              ).title ?? ""
                            : ""
                    }`}
                    dataLast={data.tabs[cardSelected].last}
                    dataThis={data.tabs[cardSelected].this}
                    dataTarget={data.tabs[cardSelected].target}
                />
            )}
        </div>
    );
};

export default MonthlyTopCountries;
