import Axios from "axios";
import { URLAPI } from "../configuration";

export const get_currency = (hotel_id) => {
    const id = hotel_id || localStorage.getItem("hotelID");
    if (id === "default") return "€";

    let currencies = JSON.parse(localStorage.getItem("currency")) || {};

    if (currencies[id]) {
        return currencies[id].symbol;
    }

    // We don't have, lets query it
    try {
        Axios.get(`${URLAPI}/hotel/currency/${id}`).then((response) => {
            currencies[id] = {
                code: response.data.data.CurrencyCode,
                symbol: response.data.data.CurrencySymbol,
            };
            localStorage.setItem("currency", JSON.stringify(currencies));

            return response.data.data.CurrencySymbol;
        });
        return "";
    } catch (error) {
        console.error("Error fetching currency:", error);
        return "€";
    }
};
